import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Footer from "../components/footer"
import { Link } from "gatsby"

const AboutPage = () => (
  <Layout>
    <SEO title="THACO | About" name="about" />
    <Header page="about" />
    <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
      <div className="container" style={{ maxWidth: 800 }}>
        <h2 className="text-center mb-5">
          <span className="bg-dark px-2 py-1">เกี่ยวกับเรา</span>
        </h2>
        <p className="text-white">
          พวกเราคือกลุ่มอดีตนักเรียนค่ายโอลิมปิกวิชาการคอมพิวเตอร์
          ที่ตั้งใจจะจัดการแข่งขันเพื่อส่งเสริมและพัฒนาเหล่านักเรียนค่ายรุ่นต่อๆ
          ไป ให้มีทักษะกระบวนการคิดและการเขียนโปรแกรมที่ดียิ่งขึ้น
        </p>
        <h4>คำชี้แจง</h4>
        <p className="text-white">
          เราไม่ใช่กลุ่มคณะผู้จัดส่งผู้แทนโอลิมปิกวิชาการ
          และมิได้มีส่วนเกี่ยวข้องกับองค์กรที่รับผิดชอบด้านโอลิมปิกวิชาการ (เช่น
          สอวน. หรือ สสวท.) โดยตรง
        </p>
        <p className="text-white">
          พวกเราเป็นเพียงกลุ่มอดีตนักเรียนค่ายคอมพิวเตอร์โอลิมปิกที่มีแนวคิดและความเชื่อมั่นร่วมกันที่จะพัฒนาคอมพิวเตอร์โอลิมปิกให้ดียิ่งขึ้นต่อไปในอนาคต
          หากสนใจร่วมงานกับพวกเรา สามารถ{" "}
          <Link to="/contact" className="pb-0 sans">
            ติดต่อเรา
          </Link>{" "}
          ได้
        </p>
        <h2 className="text-center my-5">
          <span className="bg-dark px-2 py-1">About us</span>
        </h2>
        <p className="text-white">
          We are a community of alumni from the training camps held in Thailand,
          having common goal of improving younger generations' ability to do
          competitive programming and problem solving.
        </p>
        <h4>Disclaimer</h4>
        <p className="text-white">
          We are not an officially authorized organization involving the process
          of selection, training and competition in Thailand Olympiad in
          Informatics (TOI) or International Olympiad in Informatics (IOI). The
          process of selection, training and competition is being managed
          officially by The Institute for the Promotion of Teaching Science and
          Technology (IPST) and The Promotion of Academic Olympiad and
          Development of Science Education Foundation under the patronage of Her
          Royal Highness Princess Galyani Vadhana Krom Luang Naradhiwas
          Rajanagarindra (POSN). We are just a group of people who want to
          improve and promote competitive programming in Thailand.
        </p>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default AboutPage
